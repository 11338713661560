import React from 'react'

import './CareerSection.css'

const CareerSection = () => {
  return (
    <>

        <div className='career-section'>
            <div className='career-section-content'>
                <iframe src="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=E44C4EF7C1C8D4418D54B54DF1838F5C&fromClientSide=true" title='paycom' ></iframe>
            </div>
        </div>


    </>
  )
}

export default CareerSection
