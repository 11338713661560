import React from 'react'
import Fade from 'react-reveal/Fade';

import './HuntingtonBeach.css'


const HuntingtonBeach = () => {
    return (

<>

        <div className='HuntingtonBeach submenu'>

                <div className="HuntingtonBeach-content">
                    <Fade top>
                        <h1>CHECK OUT OUR SOBER LIVING HOMES</h1>
                    <h2>HUNTINGTON BEACH, CALIFORNIA</h2>
                    </Fade>
            </div>
        </div>

        </>
    )
}

export default HuntingtonBeach
