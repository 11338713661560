import React from 'react'
import Fade from 'react-reveal/Fade';
import './Anxiety.css'

const Anxiety = () => {
    return (
        <div className='anxiety submenu'>

                <div className="anxiety-content">
                    <Fade top>
                    <h1>ANXIETY DISORDER</h1>
                    </Fade>
            </div>
        </div>
    )
}

export default Anxiety
