import React from 'react'
import Fade from 'react-reveal/Fade';

import './About.css'



const About = () => {
    return (

<>

        <div className='about'>
                <div className="about-content">
                    <Fade top>
                    <h1>ABOUT US</h1>
                    </Fade>
                </div>
            </div>
</>
    )
}

export default About
